<template>
  <div class="text-left max-w-sm md:max-w-xs rounded overflow-hidden_ hover:shadow-2xl bg-white relative cursor-pointer" @click="goToShow" @mouseover="showHover" @mouseleave="hideHover">
    <img v-if="event.event_settings.btrjla_sanctioned" class="absolute -mt-8 -ml-10" src="https://res.cloudinary.com/showman/image/upload/c_scale,h_120,a_-20/v1702670101/BTRJLA_LOGO_EXAMPLE_1.png">
    <div class="bg-gray-300">
      <img v-if="type !== 'small'" class="w-full min-w-full" loading="lazy" :src="event.event_details.event_card_cover_url ? event.event_details.event_card_cover_url : defaultCoverPhoto" :alt="`${event.name} cover photo`">
      <img v-else class="w-full" loading="lazy" :src="event.event_details.event_card_cover_url ? event.event_details.event_card_cover_url : defaultCoverPhoto" :alt="`${event.name} cover photo`">
    </div>
    <div class="px-6 py-4">
      <div class="flex justify-between">
        <div class="flex flex-col text-center pr-4">
          <div class="text-md font-medium text-calendar">{{startDate.month_abrev}}</div>
          <div class="text-lg font-bold">{{startDate.day}}<template v-if="(startDate.day !== endDate.day) && (startDate.month_full === endDate.month_full)">-{{endDate.day}}</template></div>
        </div>
        <div class="flex-1">
          <template v-if="type==='small'">
            <div class="text-sm mb-1"><span v-if="event.name.length > 18">{{event.name.substring(0,16) + '...'}}</span><span v-else>{{event.name}}</span></div>
            <div class="text-gray-800 text-xs">
              <div>{{event.location_details.city}}, {{event.location_details.state}}</div>
            </div>
          </template>
          <template v-else>
            <div class="mb-1 font-medium"><span v-if="event.name.length > 29">{{event.name.substring(0,27) + '...'}}</span><span v-else>{{event.name}}</span></div>
            <div class="text-gray-800">
              <div>{{event.location_details.city}}, {{event.location_details.state}}</div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="type==='full'" class='flex flex-wrap mt-2'>
        <template v-if="event.species">
          <span v-for="(s, i) in event.species.filter(sp => {return sp.species})"  :key="i" class="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-200 text-blue-800 mr-1">
            {{`${s.species}`.toUpperCase()}}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {format, parse} from 'date-fns'
export default {
  props: {
    event: Object,
    clickable: {
      default: true,
      type: Boolean
    },
    type: {
      default: 'full',
      type: String
    },
    forceFullLoad: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      hasRouter: this.$router,
      hover: false
    }
  },
  computed: {
    startDate () {
      let parsedDate = parse(this.event.start_date)
      return {
        month_day: format(parsedDate, 'MMM Do'),
        full_date_num: this.Localized.formatDate(this.event.start_date, this.event.location_details.country),
        month_abrev: format(parsedDate, 'MMM'),
        month_full: format(parsedDate, 'MMMM'),
        day_pretty: format(parsedDate, 'Do'),
        day: format(parsedDate, 'D')
      }
    },
    endDate () {
      let parsedDate = parse(this.event.end_date)
      return {
        month_day: format(parsedDate, 'MMM Do'),
        full_date_num: this.Localized.formatDate(this.event.end_date, this.event.location_details.country),
        month_abrev: format(parsedDate, 'MMM'),
        month_full: format(parsedDate, 'MMMM'),
        day_pretty: format(parsedDate, 'Do'),
        day: format(parsedDate, 'D')
      }
    },
    showTypeList () {
      return this.event.shows.map(s => {
        return s.name.split(' ')[0]
      })
      /* return types.filter((s, index) => {
        return types.indexOf(s) >= index
      }) */
    },
    defaultCoverPhoto () {
      return `https://res.cloudinary.com/showman/image/upload/c_fill,g_auto,w_480,ar_16:7/c_scale,co_rgb:ffffff,l_text:montserrat_50_align_center_style_bold:${this.event.name},g_center,w_400,c_fit/v1620138098/defaultPhotos/Blank_Showman_Orange.png`
    }
  },
  methods: {
    goToShow () {
      if (this.clickable) {
        if (!this.forceFullLoad) {
          this.$router.push({
            path: `/${this.event.event_identifier}/`
          })
        } else {
          window.location = `/shows/#/${this.event.event_identifier}/`
        }
      }
    },
    showHover () {
      this.hover = true
    },
    hideHover () {
      this.hover = false
    }
  }
}
</script>

<style>

</style>
